@charset "UTF-8";
@font-face {
    font-family: Notification;
    src: url(fonts/notification.eot?s3g3t9);
    src: url(fonts/notification.eot?#iefixs3g3t9) format("embedded-opentype"), url(fonts/notification.woff?s3g3t9) format("woff"), url(fonts/notification.ttf?s3g3t9) format("truetype"), url(fonts/notification.svg?s3g3t9#notification) format("svg");
    font-weight: 400;
    font-style: normal
}

.notification-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto
}

.notification, .notification-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.notification {
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    color: #fff;
    background-color: #ccc;
    -webkit-box-shadow: 0 0 12px #999;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: .9;
    margin-top: 15px
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: 700;
    margin: 0 0 5px
}

.notification:focus, .notification:hover {
    opacity: 1
}

.notification-enter {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.notification-enter.notification-enter-active {
    -webkit-transition: all .4s;
    transition: all .4s
}

.notification-enter.notification-enter-active, .notification-leave {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: all .4s;
    transition: all .4s
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    display: block;
    font-family: Notification;
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px
}

.notification-info {
    background-color: #2f96b4
}

.notification-info:before {
    content: ""
}

.notification-success {
    background-color: #51a351
}

.notification-success:before {
    content: ""
}

.notification-warning {
    background-color: #f89406
}

.notification-warning:before {
    content: ""
}

.notification-error {
    background-color: #bd362f
}

.notification-error:before {
    content: ""
}