.app-main-header {
  background-color: #ff5722 !important;
  color: #fff !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  background-color: #e12f06 !important;
}
ul.nav-menu li.menu.open .sub-menu li > a {
  margin-left: 10px;
}
.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 10px 10px 20px;
  color: #000;
}
.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}
.app-sidebar .side-nav {
  background: #fff !important;
  /* box-shadow: 5px 10px 18px #ced4da; */
}
div.mini-drawer .app-main-header.custom-color {
  background: #e12f06 !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.app-sidebar .side-nav ul.nav-menu li a:hover,
.cbutton:hover {
  background: #e12f06;
  color: #fff;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #e12f06 !important;
  color: #000;
}
label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #e12f06;
}
.page-heading i.zmdi-hc-fw {
  color: #e12f06;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #e12f06;
  color: white;
}
/* .MuiButton-root .redColor:hover {
  background-color: #e12f06 !important;
} */
.redColor {
  background: #e12f06 !important;
}
/* .app-main-content .jr-btn,
button.greenColor,
.app-login-form button {
  background-color: #2f562f !important;
  color: white;
} */
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #e12f06 !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #e12f06 !important;
}

div#form-dialog-title {
  background: #e12f06;
}

/* Status labels*/
span.label {
  background: gray;
}

span.confirmed,
span.created {
  background: #ffcb00e0;
}

span.searching {
  background: #e0b91ffc;
}

span.started {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick {
  background: #972ded;
}

span.completed,
span.Online,
span.active,
span.approved,
span.Yes,
span.yes,
span.true,
span.success {
  background: green;
}

span.cancelled,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.no,
span.false {
  background: #fd2121;
}
/* span.inactive {
  background: black;
} */
span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}
span.Offline {
  background: #eb4d4d;
}
/* .MuiButton-root:hover {
  text-decoration: none;
  background-color: rgb(152 74 22) !important;
} */
/* .MuiButton-label {
  color: #fff;
} */
.label_for {
  position: absolute;
  top: 18%;
  left: 0px;
  transform: translateY(-50%);
  font-size: 13px;
  color: #949494;
  /* pointer-events: none; */
  transition: all 0.15s ease-out 0s;
}
